.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 600px) {
  .cracha {
    display: none;
  }
}

.cracha {
  /* background:  rgb(0, 91, 171);  */
  background-size: auto 100%;
  box-shadow: 7px -7px rgba(0, 0, 0, 0.1);
  background-image: url("./images/FUNDO.png");
  border-radius: 30px;
  height: 560px;
  text-align: center;
  position: relative;
  border: 100px;
}

.buraco {
  margin-top: 5px;
  height: 20px;
  margin-left: auto;
  border-radius: 80px;
  margin-right: auto;
  width: 6em;
  background: white;
  width: 41px;

}

.footer {
  background-color: aliceblue;
  justify-content: space-between;
  margin-top: 10px;
}

.cargo {
  margin-top: 20px;
  color: #FFDF00;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: x-large
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.progress {
  width: 100px;
  height: 100px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background: rgb(0, 91, 171);

  padding-left: 0;
  padding-right: 0;
  margin: 0;
  width: 100%
}

.navbar img {
  margin-left: 8px;
}

.borda1 {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: rgb(255, 202, 8);
}

.borda2 {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: rgb(14, 167, 82);
}

body {
  font-family: "Arial";
}


.document {
  margin: auto auto;
  width: 216mm;
  height: 108mm;
  background-color: #fff;
}

.documentPix {
  margin: auto auto;
  width: 216mm;
  background-color: #fff;
}

.headerBtn {
  margin: auto auto;
  width: 216mm;
  background-color: #fff;
}

table {
  width: 100%;
  position: relative;
  border-collapse: collapse;
}

.bankLogo {
  width: 28%;
}

.boletoNumber {
  width: 62%;
  font-weight: bold;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
  right: 20px;
}

td {
  position: relative;
}

.title {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
  font-weight: bold;
}

.text {
  font-size: 12px;
}

p.content {
  padding: 0px;
  width: 100%;
  margin: 0px;
  font-size: 12px;
}

.sideBorders {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

hr {
  size: 1;
  border: 1px dashed;
}

br {
  content: " ";
  display: block;
  margin: 12px 0;
  line-height: 12px;
}


.btnDefault {
  font-kerning: none;
  font-weight: bold;
}

.btnDefault:not(:focus):not(:disabled) {
  border-color: #808080;
}

button {
  border: 1px;
  padding: 5px;
  line-height: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

i[class*=icss-] {
  position: relative;
  display: inline-block;
  font-style: normal;
  background-color: currentColor;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle
}

i[class*=icss-]:after,
i[class*=icss-]:before {
  content: "";
  border-width: 0;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

i.icss-print {
  width: .68em;
  height: 1em;
  border-style: solid;
  border-color: currentcolor;
  border-width: .07em;
  -webkit-border-radius: .05em;
  border-radius: .05em;
  background-color: transparent;
  margin: 0 .17em
}

i.icss-print:before {
  width: 1em;
  height: .4em;
  border-width: .07em .21em 0;
  border-style: solid;
  border-color: currentColor currentcolor transparent;
  -webkit-border-radius: .05em .05em 0 0;
  border-radius: .05em .05em 0 0;
  top: .25em;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, transparent), color-stop(20%, currentcolor), color-stop(60%, currentcolor), color-stop(60%, transparent));
  background-image: -webkit-linear-gradient(transparent 20%, currentcolor 20%, currentcolor 60%, transparent 60%);
  background-image: -o-linear-gradient(transparent 20%, currentcolor 20%, currentcolor 60%, transparent 60%);
  background-image: linear-gradient(transparent 20%, currentcolor 20%, currentcolor 60%, transparent 60%)
}

i.icss-print:after {
  width: .45em;
  height: .065em;
  background-color: currentColor;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: .6em;
  -webkit-box-shadow: 0 .12em, -.1em -.28em 0 .05em;
  box-shadow: 0 .12em, -.1em -.28em 0 .05em
}

i.icss-files {
  width: .75em;
  height: .95em;
  background-color: transparent;
  border: .05em solid transparent;
  border-width: 0 .05em .05em 0;
  -webkit-box-shadow: inset 0 0 0 .065em, .13em .11em 0 -.05em;
  box-shadow: inset 0 0 0 .065em, .13em .11em 0 -.05em;
  -webkit-border-radius: 0 .3em 0 0;
  border-radius: 0 .3em 0 0;
  margin: 0 .17em .05em .1em
}

i.icss-files:before {
  border-style: solid;
  border-width: .2em;
  top: .037em;
  left: .25em;
  -webkit-border-radius: .1em;
  border-radius: .1em;
  border-color: transparent currentColor transparent transparent;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.Qrbutton {
  text-decoration: none;
  background-color: #005BAB;
  color: white;
  border: 0;
  height: 50px;
  border-radius: 20px;
  box-shadow: 6px 5px 5px rgba(0, 0, 0, 0.2);
}

.certificado {
  background: url('./images/Certificado.png');
  background-size: contain;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
}

/* footer {
  background: rgb(0, 91, 171);
  position: absolute;
  bottom: -450px;
  width: 100%;
  height: 50px;
  text-align: center;
  color: white;
} */

.spanFooter {

  padding-top: 15px;
}

.contatos {
  background: rgba(0, 90, 156);
  color: 'cinza';
  padding: 10px 0;
  margin-top: 5%;
}

.containerfooter {
  max-width: 80%;
  background-color: #ffffff;
  padding: 2em;
  border-radius: 15px;
  margin-left: 12%;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: -3%;
}

.containerfooter figure {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.containerfooter .estad {
  max-width: 100%;
  height: auto;
}

.contatos {
  position: relative;
  bottom: 0;
  background: rgb(0, 91, 171);
  max-height: 120px;
  padding: 10px 0;
  margin-top: 5%;
  display: flex;
  align-items: center;
}

.contatos img {
  position: relative;
  left: 5%;
  margin: 0;
}

.logo {
  width: 25%;
  position: absolute;
}

.contatos p {
  color: #fff;
  text-decoration: none;
  margin-left: 300px;
}

.contatos a:hover {
  color: #ca7d18;
}

.redessociais {
  display: flex;
  margin-left: 7rem;
}

.logoCNM {
  width: 7%;
}

.fundo-totem {
  background: url('./images/fundo-logo.png');
  background-size: 100%;
  height: 100vh;
}

.clique-aqui {
  margin-top: 23%;
  width: 65%;
}

.logo-cnm {
  margin-top: 5%;
  width: 65%;
}

.logo-cnm-a {
  position: absolute;
  bottom: 20%;
  right: 37%;
  width: 25%;
}

.confederando-b {
  position: absolute;
  left: 0;
  bottom: 0;
}

.confederando-a {
  position: absolute;
  right: 0;
  bottom: 0;
}

.botao-dicas {
  animation: animate 1.3s linear infinite;
}

/* @media print {
  @page {
      size: landscape;
  }
} */

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.texto-dados p {
  color: red;
  font-weight: bold;
}

.texto-dados a {
  text-decoration: none;
  border: 1px solid rgba(0, 90, 156);
  background-color: #fff;
  border-radius: 25px;
  padding: 10px;
}

.texto-dados a:hover {
  cursor: pointer;
}

.textotem {
  text-align: center;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  position: absolute;
  top: 15%;
  right: 50%;
  transform: translate(50%, -50%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 12em;
  font-weight: 700;
  color: rgba(0, 90, 156);
  text-shadow: 1px 1px 1px #FFC100,
    1px 2px 1px #FFC100,
    1px 3px 1px #FFC100,
    1px 4px 1px #FFC100,
    1px 5px 1px #FFC100,
    1px 6px 1px #FFC100,
    1px 7px 1px #009600,
    1px 8px 1px #009600,
    1px 9px 1px #009600,
    1px 10px 1px #009600,
    1px 10px 1px rgba(16, 16, 16, 0.4),
    1px 14px 5px rgba(16, 16, 16, 0.2),
    1px 17px 25px rgba(16, 16, 16, 0.2),
    1px 22px 50px rgba(16, 16, 16, 0.4);
}

.textcopy {
  margin-bottom: 0;
  margin-top: 0;
  background: rgb(0, 91, 171);
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 8px;
  font-size: 13px;
  text-align: center;
}

.estado {
  transition: fill 0.3s, filter 0.3s, transform 0.3s;
}

.estado:hover {
  fill: rgba(99, 176, 178, 0.9);
  filter: drop-shadow(0 0 5px rgba(99, 176, 178, 0.9));
  transform: scale(1.01);
}

@media screen and (max-width: 1400px) {

  .contatos {
    display: flex;
    flex-direction: row;
    margin-left: -600px;
  }

  .redessociais {
    margin: 0.5rem;
  }

  .contatos li {
    padding: 0.1rem;
  }

  .redessociais {
    margin-left: 8rem;
  }

  .clique-aqui {
    margin-top: 20%;
    width: 55%;
  }

  .logo-cnm {
    margin-top: 5%;
    width: 55%;
  }

  .confederando-b {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .confederando-a {
    width: 18%;
    z-index: 99999;
  }
}

@media screen and (max-width: 1024px) {

  .redessociais {
    margin: 1rem;
  }

  .contatos li {
    padding: 0.1rem;
  }

  .redessociais {
    margin-left: 3rem;
  }

  .contatos img {
    left: 2%;
  }
}

@media screen and (max-width: 850px) {

  .end {
    display: none;
  }

  .redessociais {
    margin: auto;
  }
}

@media screen and (max-width: 500px) {

  .redessociais {
    margin-left: 12.5%;
  }

  .logoCNM {
    display: none;
  }

}